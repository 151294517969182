/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import Link from "shared/components/Link";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetEventButton" }>;
  data: PageQuery["event"];
}

const EventButton: React.FC<Props> = ({
  data,
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  if (!data?.bookingUrl || !data.buttonLabel) {
    return null;
  }

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <div sx={{ textAlign: "center" }}>
        <Link
          to={data.bookingUrl}
          sx={{
            variant: "buttons.primary",
          }}
        >
          {data.buttonLabel}
        </Link>
      </div>
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetEventButton on WidgetEventButton {
    id
    __typename
  }
`;

export default memo(EventButton);
